<template>
  <v-bottom-sheet v-model="sheet">
    <template #activator>
      <v-btn
        class="w-input-phone-prefix-btn"
        depressed
        color="grey lighten-2"
        @click.stop="sheet = true"
      >
        <img :src="current.flagPath" width="28" />
        <v-icon v-if="sheet === false"> mdi-chevron-down </v-icon>
        <v-icon v-else> mdi-chevron-up </v-icon>
      </v-btn>
    </template>
    <v-sheet
      flat
      tile
      color="grey lighten-4"
      class="pl-3 pt-2 pb-2 font-weight-bold grey--text text--darken-1"
      >{{ $t('cp_wauthenticator_login_winputphoneprefix_choose') }}</v-sheet
    >
    <v-list two-lines>
      <v-list-tile
        v-for="(country, index) in countries"
        :key="index"
        :class="country.code === current.code ? 'grey lighten-4' : undefined"
        @click.stop="handleSelectCountry(country)"
      >
        <v-list-tile-avatar>
          <v-img :src="country.flagPath" />
        </v-list-tile-avatar>
        <v-list-tile-content>
          <v-list-tile-title> {{ country.countryName }}</v-list-tile-title>
          <v-list-tile-sub-title>{{
            country.phonePrefix
          }}</v-list-tile-sub-title>
        </v-list-tile-content>
        <v-list-tile-action>
          <v-icon v-if="country.code === current.code"
            >mdi-checkbox-blank-circle</v-icon
          >
          <v-icon v-else>mdi-checkbox-blank-circle-outline</v-icon>
        </v-list-tile-action>
      </v-list-tile>
    </v-list>
  </v-bottom-sheet>
</template>

<script>
/**
 * list of alternative loggable countries
 * !todo: move away
 */
const AUTH_COUNTRIES = [
  {
    enabled: false,
    code: 'be',
    iso: 'be-BE',
    countryName: 'Belgique',
    longName: 'belgique',
    file: 'fr-FR.js',
    momentLocale: 'fr',
    flagPath: '/flags/be.svg',
    phonePrefix: '+32',
  },
]

export default {
  props: {
    value: {
      type: String,
      default: '+33',
    },
  },
  data() {
    const countries = [...this.$i18n.locales, ...AUTH_COUNTRIES].sort(
      (a, b) => {
        if (a.countryName > b.countryName) return 1
        else if (a.countryName < b.countryName) return -1
        else return 0
      }
    )
    return {
      countries,
      sheet: false,
    }
  },
  computed: {
    current() {
      return this.countries.find((c) => c.phonePrefix === this.value)
    },
  },
  methods: {
    handleSelectCountry(country) {
      this.$emit('input', country.phonePrefix)
      this.sheet = false
    },
  },
}
</script>

<style lang="scss">
.w-input-phone-prefix-btn {
  min-width: auto;
  flex: 0;
  padding: 0 8px !important;

  img {
    width: 28px;
    height: 24px;
  }
}
</style>
