<template>
  <v-form ref="form" v-model="valid">
    <div v-for="field in form" :key="field.label" class="pb-2">
      <v-text-field
        v-if="
          field.type === 'text-field' ||
          field.type === 'email' ||
          field.type === 'phone-number'
        "
        v-model="field.value"
        :label="getType(field).label"
        :placeholder="field.placeholder"
        :rules="getType(field).rules"
        :mask="getType(field).mask"
        v-bind="field.type === 'phone-number' ? enhancedProps : ''"
        align-center
        justify-center
        hide-details
        outline
      >
        <template #prepend>
          <w-input-phone-prefix
            v-if="field.type === 'phone-number'"
            v-model="updatablePhonePrefix"
            class="input-phone-prefix mt-0"
          />
        </template>
      </v-text-field>
      <v-switch
        v-if="field.type === 'switch'"
        v-model="field.value"
        :label="field.required ? `${field.label} *` : field.label"
        color="primary"
        hide-details
      ></v-switch>
      <v-checkbox
        v-if="field.type === 'checkbox'"
        v-model="field.value"
        :label="field.required ? `${field.label} *` : field.label"
        :rules="[field.required ? rules.required : []]"
        color="primary"
        hide-details
      ></v-checkbox>
      <v-textarea
        v-if="field.type === 'text-area'"
        v-model="field.value"
        :label="field.required ? `${field.label} *` : field.label"
        :placeholder="field.placeholder"
        :rules="[field.required ? rules.required : []]"
        hide-details
        outline
      ></v-textarea>
      <v-select
        v-if="field.type === 'select'"
        v-model="field.value"
        :label="field.required ? `${field.label} *` : field.label"
        :items="field.items"
        :rules="[field.required ? rules.required : []]"
        hide-details
        outline
        clearable
      ></v-select>
    </div>
  </v-form>
</template>

<script>
import WInputPhonePrefix from '../WAuthenticator/login/WInputPhonePrefix.vue'
import spoke from '~/utils/spoke'

export default {
  components: {
    WInputPhonePrefix,
  },
  props: {
    form: {
      type: Array,
      default: () => {
        return []
      },
    },
    file: {
      type: FormData,
      default: () => {
        return {}
      },
    },
    type: {
      type: String,
      default: () => {
        return null
      },
    },
    name: {
      type: String,
      default: () => {
        return null
      },
    },
    pseudonym: {
      type: String,
      default: () => {
        return ''
      },
    },
  },
  data() {
    return {
      valid: false,
      phonePrefix: '+33',
      rules: {
        required: (value) => {
          return !!value || 'Champs requis.'
        },
        emailRules: (value) => {
          const pattern = /^(|\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+\s?)$/
          return pattern.test(value) || 'E-mail valide requis'
        },
        phoneRules: (value) => {
          const pattern = /^([1-9](\d{2}){4})$/g
          return pattern.test(value) || 'Numéro de téléphone valide requis'
        },
      },
      phoneMask: '# ## ## ## ##',
    }
  },
  computed: {
    enhancedProps() {
      return {
        ...this.props,
        prefix: this.phonePrefix,
      }
    },
    updatablePhonePrefix: {
      set(v) {
        this.phonePrefix = v
      },
      get() {
        return this.phonePrefix
      },
    },
  },
  methods: {
    getType(field) {
      const tmpField = {
        label: field.label,
        rules: [],
      }
      if (field.required) {
        tmpField.rules = tmpField.rules.concat([this.rules.required])
        tmpField.label = `${field.label} *`
      }
      if (field.type === 'phone-number') {
        tmpField.rules = tmpField.rules.concat([this.rules.phoneRules])
        tmpField.mask = this.phoneMask
      }
      if (field.type === 'email') {
        tmpField.rules = tmpField.rules.concat([this.rules.emailRules])
      }
      return tmpField
    },
    async submitForm() {
      if (this.$refs.form.validate()) {
        const metadatas = {}
        for (let i = 0; i < this.form.length; i++) {
          const tmpLabel = this.form[i].label
          if (this.form[i].type === 'phone-number') {
            metadatas[tmpLabel] = this.phonePrefix.concat(this.form[i].value)
          } else {
            metadatas[tmpLabel] = this.form[i].value
          }
        }
        const type = this.type
        const pseudonym = this.pseudonym
        const slug = this.name
        const response = await spoke.http.post(`/feedback`, {
          slug,
          pseudonym,
          type,
          metadatas,
        })

        const feedbackID = response.item.id

        if (this.type === 'audio') {
          await spoke.http.post(`/feedback/${feedbackID}/media`, this.file)
        }
        this.$emit('valid', true)
      } else {
        this.$emit('valid', false)
      }
    },
  },
}
</script>

<style scoped lang="scss">
.input-phone-prefix {
  margin: -10px -10px 0 -5px !important;
}
</style>
