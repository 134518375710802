<template>
  <v-dialog v-model="showFeedbackModal" width="500" persistent scrollable>
    <v-card class="w-feedback-popup">
      <v-toolbar flat class="subheading text-uppercase" color="white">
        {{ title }}
        <v-spacer></v-spacer>
        <v-btn icon small @click.stop="handleClose()">
          <v-icon color="black">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider class="mb-2 mx-3"></v-divider>
      <v-layout row class="mx-auto px-4 py-2">
        <p v-if="!isRecorded && !isSend" class="text-xs-center subheading">
          {{ headCaption }}
        </p>
        <p
          v-if="isRecorded && !isSend && isRecord"
          class="text-xs-center subheading"
        >
          {{ $t('cp_wfeedbackpopup_record_ready') }}
          <br />
          {{ $t('cp_wfeedbackpopup_listen') }}
        </p>
        <p v-if="isSend" class="text-xs-center subheading">
          {{ $t('cp_wfeedbackpopup_thanks') }}
          <br />
          {{ formSentMessage }}
        </p>
      </v-layout>
      <v-layout
        v-if="!isRecorded && isRecord && !isSend"
        class="mx-auto px-3 py-2"
      >
        <p
          v-if="
            !isRecorded && !isSend && maxRecordDuration > 60 && secondes != 0
          "
          class="text-xs-center body-1 font-italic"
        >
          {{ $t('cp_wfeedbackpopup_max_duration', [minutes, secondes]) }}
        </p>
        <p
          v-if="
            !isRecorded && !isSend && maxRecordDuration >= 60 && secondes === 0
          "
          class="text-xs-center body-1 font-italic"
        >
          {{ $t('cp_wfeedbackpopup_max_duration_exact', [minutes]) }}
        </p>
        <p
          v-if="!isRecorded && !isSend && maxRecordDuration < 60"
          class="text-xs-center body-1 font-italic"
        >
          {{ $t('cp_wfeedbackpopup_max_duration_less', [maxRecordDuration]) }}
        </p>
      </v-layout>
      <v-layout v-if="isRecord" row class="mx-auto px-3 py-2 text-xs-center">
        <v-btn
          v-if="!isRecording && !isRecorded && !isSend"
          class="w-feedback-popup__main-button m-btn"
          color="primary"
          fab
          flat
          outline
          @click="startRecord()"
        >
          <v-icon large> mdi-microphone </v-icon>
        </v-btn>
        <v-btn
          v-if="isRecording && !isRecorded"
          :ripple="false"
          class="w-feedback-popup__main-button"
          color="primary"
          fab
          depressed
        >
          <v-progress-circular
            v-if="isRecording && !isRecorded"
            v-model="percentageCount"
            class="w-feedback-popup__main-button"
            color="white"
            :size="145"
            :width="4"
            rotate="-90"
          >
            <v-icon large> mdi-microphone </v-icon>
          </v-progress-circular>
        </v-btn>
        <v-btn
          v-if="!isRecording && isRecorded && !isPlay"
          class="w-feedback-popup__main-button m-btn"
          color="primary"
          fab
          flat
          outline
          @click="playRecord(recordedSound)"
        >
          <v-icon large> mdi-play </v-icon>
        </v-btn>
        <v-btn
          v-if="!isRecording && isRecorded && isPlay"
          class="w-feedback-popup__main-button m-btn"
          color="primary"
          fab
          depressed
          @click="playRecord(recordedSound)"
        >
          <v-icon large> mdi-pause </v-icon>
        </v-btn>
        <v-progress-circular
          v-if="isSend"
          value="100"
          class="w-feedback-popup__main-button"
          color="primary"
          :size="145"
          :width="3"
          rotate="-90"
        >
          <v-icon large color="primary"> mdi-check </v-icon>
        </v-progress-circular>
      </v-layout>
      <v-layout v-if="isRecord" row class="mx-auto px-3">
        <v-btn
          v-if="!isRecording && !isRecorded"
          color="white"
          class="s-btn hide"
          fab
          flat
          outline
          disabled
        >
          <v-icon large> mdi-stop </v-icon>
        </v-btn>
        <v-btn
          v-show="isRecording && !isRecorded"
          color="primary"
          class="s-btn"
          fab
          flat
          outline
          @click="stopRecord()"
        >
          <v-icon large> mdi-stop </v-icon>
        </v-btn>
        <v-btn
          v-if="!isRecording && isRecorded"
          color="primary"
          class="s-btn"
          fab
          flat
          outline
          @click="resetRecord()"
        >
          <v-icon large> mdi-arrow-u-left-top </v-icon>
        </v-btn>
      </v-layout>
      <v-layout
        v-if="isRecorded || (!isRecord && !isSend)"
        row
        class="px-3 py-2"
      >
        <v-flex d-flex xs11 md7 mx-auto>
          <v-text-field
            v-model="pseudonym"
            :label="labelPseudo"
            :placeholder="placeholderPseudo"
            outline
            hide-details
          ></v-text-field>
        </v-flex>
      </v-layout>
      <v-layout row class="px-3">
        <v-flex d-flex xs11 md7 mx-auto>
          <WFeedbackForm
            v-if="isRecorded || (!isRecord && !isSend)"
            ref="childComponentRef"
            v-model="form"
            :form="form"
            :file="file"
            :type="type"
            :name="name"
            :pseudonym="pseudonym"
            @valid="isValid"
          />
        </v-flex>
      </v-layout>
      <v-snackbar
        v-model="snackbar"
        :timeout="3000"
        color="error"
        top
        elevation="25"
      >
        {{ $t('cp_wfeedbackpopup_error') }}
      </v-snackbar>
      <v-snackbar
        v-model="isNotAllowed"
        top
        color="error"
        elevation="25"
        multi-line
        class="font-weight-bold subheading"
      >
        {{ $t('cp_wfeedbackpopup_notallowed') }}
        <v-btn color="white" flat @click="isNotAllowed = false">
          <v-icon right> mdi-close </v-icon>
        </v-btn>
      </v-snackbar>
      <v-layout row class="mb-2 px-3 py-2">
        <v-flex d-flex xs10 md6 mx-auto>
          <v-btn
            v-if="isRecorded || (!isRecord && !isSend)"
            class="btn"
            block
            solo
            large
            color="primary"
            @click="sendRecord()"
          >
            {{ $t('cp_wfeedbackpopup_send') }}
          </v-btn>
          <v-btn
            v-if="isSend && !isRecord"
            class="font-weight-bold"
            block
            large
            color="primary"
            @click="handleClose()"
          >
            {{ $t('cp_wfeedbackpopup_close') }}
          </v-btn>
          <v-btn
            v-if="isSend && isRecord"
            class="btn"
            block
            large
            color="primary"
            @click="backToPlayer()"
          >
            {{ $t('cp_wfeedbackpopup_back') }}
          </v-btn>
        </v-flex>
      </v-layout>
      <v-layout v-if="form">
        <v-flex d-flex xs10 mx-auto>
          <p
            v-if="(isRecorded || (!isRecord && !isSend)) && isFormError"
            class="text-xs-center font-weight-bold error--text"
          >
            {{ $t('cp_wfeedbackpopup_required') }}
          </p>
          <p
            v-if="(isRecorded || (!isRecord && !isSend)) && !isFormError"
            class="text-xs-center caption"
          >
            {{ $t('cp_wfeedbackpopup_required') }}
          </p>
        </v-flex>
      </v-layout>
      <v-layout row class="py-2">
        <v-flex d-flex xs10 mx-auto>
          <div
            v-if="!isSend && (isRecorded || (!isRecord && !isSend))"
            class="text-xs-center caption"
            v-html="subCaption"
          ></div>
        </v-flex>
      </v-layout>
    </v-card>
  </v-dialog>
</template>

<script>
import MediaPlayer from '~/@mediam/spoke-js/services/MediaPlayer'
import { getConfig } from '~/utils/voicer'
import WFeedbackForm from '~/components/WFeedbackForm'

export default {
  components: {
    WFeedbackForm,
  },

  props: {
    value: {
      type: Boolean,
    },
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      minutes: 0,
      secondes: 0,
      isRecording: false,
      isRecorded: false,
      isSend: false,
      isPlay: false,
      isFormError: false,
      isRecord: undefined,
      type: '',
      name: '',
      pseudonym: '',
      title: '',
      subCaption: '',
      formSentMessage: '',
      count: 0,
      percentageCount: 0,
      interval: {},
      recordedSound: undefined,
      audio: undefined,
      file: undefined,
      valid: false,
      snackbar: false,
      isNotAllowed: false,
    }
  },
  computed: {
    showFeedbackModal: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  beforeMount() {
    if (getConfig(`WFeedBack.${this.id}`) && this.id) {
      this.maxRecordDuration = getConfig(`WFeedBack.${this.id}.maxDuration`)
      this.headCaption = getConfig(`WFeedBack.${this.id}.headCaption`)
      this.title = getConfig(`WFeedBack.${this.id}.title`)
      this.form = getConfig(`WFeedBack.${this.id}.recorderForm`)
      this.name = getConfig(`WFeedBack.${this.id}.name`)
      this.subCaption = getConfig(`WFeedBack.${this.id}.subCaption`)
      this.formSentMessage = getConfig(`WFeedBack.${this.id}.formSentMessage`)
      this.type = getConfig(`WFeedBack.${this.id}.type`)
      if (this.type === 'audio') {
        this.isRecord = true
      }
      this.labelPseudo = getConfig(`WFeedBack.${this.id}.pseudonym.label`)
      this.placeholderPseudo = getConfig(
        `WFeedBack.${this.id}.pseudonym.placeholder`
      )
      this.minutes = Math.floor(this.maxRecordDuration / 60)
      this.secondes = this.maxRecordDuration % 60
    } else {
      throw new Error('No feedback')
    }
  },
  methods: {
    async startRecord() {
      let chunks = []
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        await navigator.mediaDevices
          .getUserMedia({ audio: true })
          .then((stream) => {
            this.mediaRecorder = new MediaRecorder(stream)
            this.mediaRecorder.ondataavailable = function (e) {
              chunks.push(e.data)
            }
            this.mediaRecorder.onstop = (e) => {
              const blob = new Blob(chunks, { type: 'video/mp4' })
              this.file = new FormData()
              this.file.append('file', blob)
              chunks = []
              this.recordedSound = window.URL.createObjectURL(blob)
              this.audio = new Audio(this.recordedSound)
              this.audio.addEventListener('ended', this.audioEnded)
            }
            this.mediaRecorder.start()
            this.isRecording = true
            this.count = 0
            this.percentageCount = 0
            // TODO: make it smoother
            this.interval = setInterval(() => {
              this.count += 1
              this.percentageCount = this.count * (100 / this.maxRecordDuration)
              if (this.count >= this.maxRecordDuration) {
                this.stopRecord()
              }
            }, 1000)
          })
          .catch((e) => {
            console.log(e)
            this.isNotAllowed = true
          })
      } else {
        console.log('NOT supported')
        throw new Error('NOT supported')
      }
      MediaPlayer.pause()
    },

    stopRecord() {
      this.mediaRecorder.stop()
      this.isPlay = false
      this.isRecording = false
      this.isRecorded = true
      this.percentageCount = 0
      clearInterval(this.interval)
    },

    resetRecord() {
      this.isRecorded = false
      this.isSend = false
      this.isPlay = false
      this.isFormError = false
      this.percentageCount = 0
      this.audio.pause()
      clearInterval(this.interval)
    },

    backToPlayer() {
      this.handleClose()
      MediaPlayer.play()
    },

    async sendRecord() {
      await this.$refs.childComponentRef.submitForm()
      if (this.valid) {
        this.isSend = true
        this.isRecorded = false
        this.isFormError = false
      } else {
        this.isFormError = true
        this.snackbar = true
      }
    },

    isValid(value) {
      this.valid = value
    },

    playRecord() {
      if (this.isPlay === false) {
        this.isPlay = true
        this.audio.play()
      } else {
        this.isPlay = false
        this.audio.pause()
      }
    },

    audioEnded() {
      this.isPlay = false
    },

    handleClose() {
      if (this.form) {
        for (let i = 0; i < this.form.length; i++) {
          this.form[i].value = ''
        }
      }

      clearInterval(this.interval)
      if (this.audio) {
        this.stopRecord()
      }
      if (this.isPlay === true) {
        this.isPlay = false
        this.audio.pause()
      }
      this.percentageCount = 0
      this.isRecording = false
      this.isRecorded = false
      this.isSend = false
      this.showFeedbackModal = false
      this.isFormError = false
    },
  },
}
</script>

<style lang="scss" scoped>
.btn {
  font-family: Rubik;
  padding: 5px;
}

.s-btn.v-btn--outline {
  border-width: 2px;
}

.m-btn.v-btn--outline {
  border-width: 3px;
}

.v-btn--block {
  border-radius: 5px;
  .v-icon {
    font-size: 16px;
  }
}

.hide {
  visibility: hidden;
}

.w-feedback-popup {
  padding-bottom: 15px;
  overflow-y: auto;
  border-radius: 12px;
}

@media screen and (min-width: 600px) {
  .w-feedback-popup {
    height: auto !important;
  }
}

@media screen and (max-height: 600px) {
  .w-feedback-popup__main-button {
    width: 120px;
    height: 120px;

    .v-icon {
      font-size: 50px !important;
    }
  }
}

@media screen and (min-height: 601px) {
  .w-feedback-popup__main-button {
    width: 150px;
    height: 150px;

    .v-icon {
      font-size: 75px !important;
    }
  }
}
</style>
